// extracted by mini-css-extract-plugin
export var clickArea = "index-module--clickArea--C3PiH";
export var content = "index-module--content--YTrRX";
export var contentHover = "index-module--contentHover--lRSlY";
export var descBg = "index-module--descBg--PHxUN";
export var descContent = "index-module--descContent--5HvXp";
export var experienceDemo = "index-module--experienceDemo--Q8AGF";
export var experienceKPI = "index-module--experienceKPI--LknIr";
export var guidance = "index-module--guidance--9SYTG";
export var guidanceLogo = "index-module--guidanceLogo--a35i2";
export var hoverShow = "index-module--hoverShow--4nonp";
export var hoverUp = "index-module--hover-up--iM0mL";
export var insurancePage = "index-module--insurancePage--37Lt5";
export var position_left = "index-module--position_left--5Oup4";
export var viewData = "index-module--viewData--74YIE";
export var watchVideo = "index-module--watchVideo--wU7FP";